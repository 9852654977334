import React, { useState } from "react";
import style from "../style.module.css";
import { FiCheckCircle, FiMoreVertical, FiXSquare } from "react-icons/fi";
import { months, timesheetStatus } from "./../../../Helpers/data";
import Popup from "./../../Popup/index";
import toast from "../../../Helpers/toast";

const ListItem = ({ timesheet, onApprove = (f) => f, onReject = (f) => f }) => {
  const [showMore, setShowMore] = useState(false);
  const [confirmApprove, setConfirmApprove] = useState(false);
  return (
    <>
      <div className={`${style["timesheets-table-item"]}`}>
        <div className="small-text bold text-truncate">
          CRA {months[timesheet.month - 1]} {timesheet.year}
        </div>
        <div className="small-text d-none d-md-flex">
          {timesheet.createdAt &&
            new Date(timesheet.createdAt).toLocaleDateString()}
        </div>
        <div className="small-text d-none d-md-flex">
          {timesheet.status === "ADMIN_APPROVED" &&
            timesheet.updatedAt &&
            new Date(timesheet.updatedAt).toLocaleDateString()}
        </div>
        <div
          className={`${style["timesheets-status"]} small-text`}
          status={timesheet.status}
        >
          {timesheetStatus[timesheet.status]}
        </div>
        <div className="header-4 green-text text-center">
          <FiMoreVertical
            className="cursor-pointer"
            onClick={(e) => setShowMore(true)}
          />
        </div>
        {showMore && (
          <>
            <div
              className={`${style["show-more"]} d-flex flex-column align-items-center py-3 gap-3`}
            >
              <div
                className="small-text green-text cursor-pointer"
                onClick={(e) => {
                  setConfirmApprove(true);
                  setShowMore(false);
                }}
              >
                <FiCheckCircle className="mx-1" /> Valider le CRA
              </div>
              <div
                className="small-text red-text cursor-pointer"
                onClick={(e) => {
                  onReject(timesheet).then(() => {
                    setShowMore(false);
                    toast("success", "Le CRA a été refusé");
                  });
                }}
              >
                <FiXSquare className="mx-1" />
                Rejeter le CRA
              </div>
            </div>
            <div
              className={`${style["close-show-more"]}`}
              onClick={(e) => setShowMore(false)}
            ></div>
          </>
        )}
      </div>
      {confirmApprove && (
        <Popup
          size="sm"
          show={confirmApprove}
          onClose={(e) => setConfirmApprove(false)}
        >
          <div className="row p-4 gap-4 justify-content-center">
            <div className="col-12 small-text">
              Etes vous sûr(e) de vouloir valider le compte rendu d'activité ?
            </div>
            <div className="col-5">
              <button
                className="default-btn danger-btn"
                onClick={(e) => setConfirmApprove(false)}
              >
                Annuler
              </button>
            </div>
            <div className="col-5">
              <button
                className="default-btn"
                onClick={(e) => {
                  onApprove(timesheet).then(() => {
                    setConfirmApprove(false);
                    toast("success", "Le CRA a été approuvé");
                  });
                }}
              >
                Confirmer
              </button>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ListItem;
