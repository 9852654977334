const TotalSummary = ({ listOfData, classes, isFilteredByClaim }) => {
  const getTotal = (listOfData) => {
    let total = {
      totalTimesheetSubmitted: 0,
      totalTimesheetValidated: 0,
      totalInvoiceSubmitted: 0,
      totalInvoiceValidated: 0,
      totalDays: 0,
    };

    const totalTimesheetSubmitted = listOfData
      .map((data) => data.timesheetstatus)
      .filter(
        (status) =>
          status === "SUBMITTED" ||
          status === "ADMIN_APPROVED" ||
          status === "APPROVED" ||
          status === "SENT_TO_SIGN"
      ).length;

    const totalTimesheetValidated = listOfData
      .map((data) => data.timesheetstatus)
      .filter(
        (status) => status === "ADMIN_APPROVED" || status === "APPROVED"
      ).length;

    const totalInvoiceSubmitted = listOfData
      .map((data) => data.invoicestatus)
      .filter((status) => status === "PENDING" || status === "APPROVED").length;

    const totalInvoiceValidated = listOfData
      .map((data) => data.invoicestatus)
      .filter((status) => status === "APPROVED").length;

    const totalDays = listOfData
      .filter((data) => data.timesheetstatus !== "REJECTED")
      .filter((data) => data.duration)
      .map((data) => data.duration)
      .reduce((acc, curr) => +acc + +curr, 0);

    return {
      ...total,
      totalTimesheetSubmitted,
      totalTimesheetValidated,
      totalInvoiceSubmitted,
      totalInvoiceValidated,
      totalDays,
      size: listOfData?.length,
    };
  };

  const {
    totalDays,
    totalTimesheetSubmitted,
    totalTimesheetValidated,
    totalInvoiceSubmitted,
    totalInvoiceValidated,
    size,
  } = getTotal(listOfData);

  return (
    <div className={`flex d-flex ${classes}`}>
      <div style={{ textAlign: "center" }}>Total : {size}</div>
      <div></div>
      <div></div>
      <div>{isFilteredByClaim ? "-" : totalDays}</div>
      <div>{totalTimesheetSubmitted}</div>
      <div>{totalTimesheetValidated}</div>
      <div>{totalInvoiceSubmitted}</div>
      <div>{totalInvoiceValidated}</div>
    </div>
  );
};

export default TotalSummary;
